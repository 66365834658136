@use "sass:meta" as ---gpkuddoqnu;@import "utils";

.pac-container {
  @include text-platform-main;

  width: 250px !important;
  margin-top: rem-calc(20px);
  margin-left: rem-calc(-14px);
  padding: rem-calc(8 16);

  border-radius: 12px;
  cursor: pointer;
  transition: color $duration $easing, background-color $duration $easing;
  background-color: $c-white;

  .pac-icon {
    display: none;
  }

  .pac-item {
    width: 100%;
    padding: rem-calc(8 16);
    cursor: pointer;
    border: none;
    opacity: 0.6;

    .pac-item-query {
      @include text-platform-main;
      font-weight: $font-weight_medium;

      .pac-matched {
        @include text-platform-main;
        font-weight: $font-weight_extra-bold;
      }
    }

    span {
      @include text-platform-small;
    }

    &:hover {
      opacity: 1;
      background-color: $c-white;
    }
  }
}

.pac-logo {
  &::after {
    width: 100%;
  }
}

;@include ---gpkuddoqnu.load-css("sass-embedded-legacy-load-done:75079");