@use "sass:meta" as ---gpkuddoqnu;@import "utils";

.ultraUnderline {
  position: relative;
  text-decoration: none;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: 1px;

    background-color: currentColor;
    transform: scaleX(0);
    transition: transform $duration;
    transform-origin: right center;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

;@include ---gpkuddoqnu.load-css("sass-embedded-legacy-load-done:75056");